import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"

const Image = ({ filename, alt, classname, style }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })
      if (!image) {
        return null
      }

      const { fluid } = image.node.childImageSharp
      return <Img style={style} alt={alt} fluid={fluid} className={classname} />
    }}
  />
)

Image.defaultProps = {
  alt: "",
}

export default Image
